import styled from "styled-components"
import { Link } from "gatsby"

export const AvenueButton = styled(Link)`
  border-radius: 3px;
  display: ${props =>
    props.fullwidth === "true" ? "inline-flex" : "inline-flex"};
  width: ${props => (props.fullwidth === "true" ? "100%" : "auto")};
  transition: 120ms ease 0s;
  outline: none;
  border: none;
  justify-content: center;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  padding: 0 20px;
  font-size: 18px;
  color: ${props =>
    props.nobackground === "true"
      ? props.theme.btnColor
      : props.theme.btnTextColor};
  background: ${props =>
    props.nobackground === "true" ? "none" : props.theme.btnColor};
  border: ${props =>
    props.nobackground === "true" && `2px solid ${props.theme.btnColor}`};
  font-weight: 600;
  height: 48px;

  :hover {
    background: ${props =>
      props.nobackground === "true"
        ? props.theme.btnColor
        : props.theme.btnHoverColor};

    color: ${props => (props.nobackground === "true" ? "white" : null)};
  }
`

export const Icon = styled.div`
  width: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: auto;
    /* g,
    path {
      fill: white;
    } */
  }
`

export const Label = styled.span`
  position: relative;
  :after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #abc8e7;
    opacity: 0;
  }
`
