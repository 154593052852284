import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "UI/MainColumn"

export const NavBarMainContainer = styled.div`
  width: 100%;
  background: #ffffff;
  z-index: 0;
  @media ${device.desktop} {
    position: relative;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
  }
`
export const NavBarInnerContainer = styled.div`
  @media ${device.desktop} {
    max-width: 1200px;
    width: calc(100% - 2 * 40px);
    margin: 0 auto;
    display: flex;
  }
`

export const NavBarContainer = styled.div`
  position: relative;
  padding: 11px 15px;
  max-width: 1200px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 3;

  @media ${device.tablet} {
    padding: 11px 30px;
  }

  @media ${device.desktop} {
    padding: 19px 0;
    width: 20%;
    margin: 0 auto;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 00);
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;

  > svg,
  > img {
    width: 115px;
    height: 40px;
    display: block;
    @media ${device.desktop} {
      width: 181px;
      height: 63px;
    }
  }
`

export const NavWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.desktop} {
    position: relative;
    width: calc(100% - 2 * 30px);
    max-width: 1200px;
    right: 0;
    top: 22px;
    margin: 0 auto;
    justify-content: flex-end;
  }
`

export const Nav = styled.nav`
  position: absolute;
  top: 62px;
  left: 0;
  margin: 0;
  background: ${(props) => props.theme.aliceBlue};
  width: 100vw;
  display: ${(props) => (props.isNavShown ? "flex" : "none")};
  z-index: 1;
  box-shadow: 0 21px 50px 0 rgba(0, 0, 0, 0.15);

  @media ${device.desktop} {
    display: flex;
    position: static;
    bottom: auto;
    left: auto;
    width: 100%;
    background: none;
    transform: none;
    justify-content: flex-end;
    box-shadow: 0 21px 50px 0 rgba(0, 0, 0, 0);
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding-top: 13px;
  padding-bottom: 15px;
  @media ${device.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const NavListItem = styled.li`
  font-weight: 600;
  color: ${(props) => props.theme.linkColor};
  width: 100%;
  padding: 10px 20px;
  position: relative;

  @media ${device.desktop} {
    width: auto;
  }

  a {
    display: block;
    text-align: center;
    color: inherit;

    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /* hover style */
  span {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: ${(props) => props.theme.linkColor};
    }
  }

  :hover {
    span::before {
      opacity: 1;
      bottom: -3px;
    }
  }
`

export const QuickSearchWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  max-height: 60px;
  height: 100%;
  opacity: 1;
  transition: 110ms;

  :hover {
    opacity: 0.7;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`

export const Clickable = styled.div`
  display: block;
  z-index: 4;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 22px;
  right: 18px;

  @media ${device.tablet} {
    right: 30px;
  }

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 16px;
  position: relative;
  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: ${(props) => props.theme.linkColor};
  position: absolute;
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${(props) => (props.isNavShown ? 0 : 1)};
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${(props) =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 16px, 0) rotate(0deg)"};
  display: block;
`
