import React from "react"
import { AvenueButton, Label, Icon } from "./style"

const Button = ({
  link,
  onClick,
  submit,
  label,
  icon,
  fullWidth,
  noBackground,
  className,
}) => {
  const isButton = onClick || submit
  return (
    <AvenueButton
      as={isButton && "button"}
      type={submit && "submit"}
      onClick={() => onClick && onClick()}
      to={link || "/"}
      className={`${className} avenueBtn`}
      nobackground={noBackground ? "true" : "false"}
      fullwidth={fullWidth ? "true" : "false"}
    >
      {icon && <Icon className="icon">{icon}</Icon>}
      <Label className="label">{label}</Label>
    </AvenueButton>
  )
}

export default Button
