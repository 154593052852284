import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "UI/MainColumn"
import AvenueImg from "UI/AvenueImg"

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1660px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* z-index:-1; */
  @media ${device.desktop} {
    display: block;
  }
`

export const HeroImage = styled(AvenueImg)`
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  height: 248px;
  z-index: -1;
  top: 0;
  display: ${(props) => (props.internal ? "none" : "block")};
  margin-bottom: -20px;

  @media ${device.tablet} {
    height: 300px;
  }

  @media ${device.desktop} {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 50%;
    left: auto;
    top: 100px;
  }

  @media ${device.large} {
    height: 450px;
  }
`

export const HeaderContentWrapper = styled(MainColumn)`
  @media ${device.desktop} {
    padding: ${(props) => (props.internal ? "58px" : "80px")} 0;
    padding-bottom: 50px;
    display: ${(props) => (props.internal ? "flex" : "")};
    flex-direction: ${(props) => (props.internal ? "row" : "")};
    align-items: ${(props) => (props.internal ? "center" : "")};
    justify-content: ${(props) => (props.internal ? "space-between" : "")};
  }
`
export const HeaderText = styled.div`
  margin-top: 31px;

  @media ${device.tablet} {
    margin-top: ${(props) => (props.internal ? "45px" : "39px")};
  }

  @media ${device.desktop} {
    margin-top: 9px;
    width: 41%;
  }
  h1 {
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -0.24px;
    line-height: 100%;
    color: ${(props) => props.theme.balticSea};
    left: 0;

    @media ${device.tablet} {
      font-size: ${(props) => (props.internal ? "48px" : "32px")};
    }

    @media ${device.desktop} {
      font-size: ${(props) => (props.internal ? "48px" : "56px")};
      letter-spacing: 0;
      line-height: 64px;
    }
    @media ${device.large} {
      font-size: 56px;
    }
  }
`
export const HeaderExcerpt = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: ${(props) => props.theme.balticSea};
  letter-spacing: 0;
  line-height: 24px;
  display: ${(props) => (props.internal ? "none" : "block")};
  margin-top: 13px;

  @media ${device.desktop} {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 13px;
  }
`
export const HeaderSearchInputTitle = styled.p`
  display: ${(props) => (props.internal ? "none" : "block")};
  font-size: 12px;
  color: #646f77;
  letter-spacing: -0.12px;
  line-height: 30px;
  margin-top: 14px;

  @media ${device.desktop} {
    display: none;
  }
`

export const HeaderSeacrhContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e9eef2;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: ${(props) => (props.internal ? "none" : "flex")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.internal ? "13px" : "7px")};
  padding: ${(props) => (props.internal ? "6px" : "15px;")};
  padding-left: ${(props) => (props.internal ? "18px" : "25px;")};
  padding-right: ${(props) => (props.internal ? "14px" : "15px;")};

  @media ${device.tablet} {
    margin-top: ${(props) => (props.internal ? "17px" : "7px")};
    padding: ${(props) =>
      props.internal ? "24px 25px 20px 31px" : "24px 25px 24px 40px"};
  }

  @media ${device.desktop} {
    margin-top: ${(props) => (props.internal ? "0" : "70px")};
    width: ${(props) => (props.internal ? "57%" : "100%")};
  }

  @media ${device.large} {
    margin-top: ${(props) => (props.internal ? "0" : "70px")};
    width: ${(props) => (props.internal ? "57%" : "100%")};
     padding: ${(props) =>
       props.internal ? "24px 25px 20px 31px" : "25px 25px 25px 41px"};
  }
  }
  .avenueBtn {
    display: none;
    @media ${device.tablet} {
      display: flex;
      width: 26%;
      padding: 0 ${(props) => (props.internal ? "36px" : "67px")};
      height: ${(props) => (props.internal ? "55px" : "46px")};
      
      span {
        font-size: 18px ;
      }
    }
    @media ${device.desktop} {
      display: flex;
      width: ${(props) => (props.internal ? "29%" : "15%")};
       

    }
    @media ${device.large} {
      display: flex;
      width: ${(props) => (props.internal ? "29%" : "18%")};
       height: ${(props) => (props.internal ? "55px" : "58px")};
     
    }
  }

  svg {
    width: 35px;
    height: 47px;
    :first-child {
      display: none;
      margin-right: 20px;
    }
    @media ${device.tablet} {
      :first-child {
        display: block;
        width: 47px;
        height: 26px;
        margin-right: ${(props) => (props.internal ? "6px" : "15px")};
      }
      :last-child {
        display: none;
      }
    }
    @media ${device.desktop} {
      :first-child {
        width: ${(props) => (props.internal ? "65px" : "26px")};
        margin-right: ${(props) => (props.internal ? 0 : "15px")};
      }
    }
    @media ${device.large} {
      :first-child {
        height: 25px;
        
      }
    }
  }
`
export const HeaderSeacrhInput = styled.input`
  font-weight: 400;
  font-size: 16px;
  color: #9da6ad;
  letter-spacing: -0.17px;
  margin-bottom: 0 !important;
  border: none;
  padding: 0;
  width: ${(props) => (props.internal ? "69%" : "83%")};

  @media ${device.tablet} {
    font-size: 24px;
    letter-spacing: -0.25px;
    width: ${(props) => (props.internal ? "100%" : "83%")};
    padding-right: ${(props) => (props.internal ? "20px" : "")};
  }
  :focus,
  textarea {
    outline: none !important;
  }
`
