import styled from "styled-components"
import { device } from "breakpoints"
import AvenueLink from "UI/AvenueLink"
import { Col } from "UI/Grid"

export const FooterContainer = styled.div`
  /* margin-top: 100px; */
  padding: 50px 0;
  overflow: hidden;
  background: #f9fafb;
  margin-top: 60px;

  @media ${device.desktop} {
    margin-top: 100px;
  }
`

export const LogoWrapper = styled(AvenueLink)`
  display: flex;
  align-items: center;
  height: auto;

  > svg,
  > img {
    width: 140px;
    height: 28px;
    display: block;
  }
`

export const FooterLink = styled(AvenueLink)`
  font-weight: 500;
  font-size: 12px;
  color: #2e8473;
  letter-spacing: 0;
  line-height: 100%;
  margin-bottom: 30px;
  text-transform: uppercase;

@media ${device.desktop} {
  margin-bottom: 15px;

}

  :last-child {
    margin-bottom: 0;
  }

  /* @media ${device.tablet} {
    margin-bottom: 0px;
    margin-right: 30px;
  } */

  :hover {
    color: ${(props) => props.theme.primaryColor};
    transition: 80ms;
  }
`

export const Copyright = styled.div`
  font-size: 12px;
  color: #9cacae;
  letter-spacing: 0;
  @media ${device.desktop} {
    max-width: 50%;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor};
    :hover {
      opacity: 0.6;
      transition: 80ms;
    }
  }
`

export const FooterLinksMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
`

export const IconText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  svg {
    width: 22px;
    height: 16px;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
`
export const FooterAddress = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #656f76;
  letter-spacing: 0;
  line-height: 24px;
  width: 230px;

  @media ${device.desktop} {
    font-size: 14px;
  }
`

export const FooterPhone = styled.p`
  font-weight: 500;
  font-size: 15px;
  color: #656f76;
  letter-spacing: 0;
  line-height: 24px;
  @media ${device.desktop} {
    font-size: 14px;
  }

  span {
    font-weight: 500;
    display: block;
    width: 22px;
    color: #1b7e8d;
    margin-right: 10px;
    @media ${device.desktop} {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
`

export const FooterPhoneNumber = styled(FooterPhone)`
  font-weight: normal;
`

export const FooterMenuLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media ${device.desktop} {
    margin-top: 0;
    align-items: flex-start;
  }

  svg {
    width: 72px;
    height: 73px;
  }
`

export const FooterCol = styled(Col)`
  margin-top: 20px;
  padding-top: 20px !important;
  border-top: 1px solid #c4cfd7;

  @media ${device.desktop} {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    padding-left: 0 !important ;
    padding-right: 0 !important ;
    margin-bottom: 50px !important;
    margin-left: 50px !important;
    max-width: calc(100% - 63px) !important;
  }
`
