import styled from "styled-components"
import { device } from "breakpoints"
export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 30px;

  @media ${device.desktop} {
    margin-top: 0;
    max-width: 50%;
    align-self: center;
  }

  a {
    margin-right: 23px;
    :last-of-type {
      margin-right: 0;
    }
    svg {
      display: block;
      height: 22px;
      width: 22px;

      &.youtube {
        width: 32px;
      }
      g,
      path {
        fill: #42bba3;
      }
    }

    :hover {
      opacity: 0.6;
      transition: 70ms;
    }
  }
`
