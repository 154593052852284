import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "UI/Button"

export const WhiteButton = styled(Button)`
  background: white;
  position: relative;
  overflow: visible;
  background-image: linear-gradient(-32deg, #2e648a 0%, #67dbc3 92%);
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
  height: 44px;
  font-size: 14px;
  z-index: 1;
  @media ${device.desktop} {
    height: 60px;
    padding: 0 38px;
    width: auto;
    font-size: 15px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: white;
    border-radius: inherit;
    opacity: 1;
    transition: 120ms;
  }

  :hover {
    color: white;

    &:before {
      opacity: 0;
    }
  }
`
