import React from "react"
import { NavListItem } from "./style"
import { Link } from "gatsby"
import NestedMenu from "./NestedMenu"

const NavBar = ({ label, path, menu, active }) => {
  return (
    <NavListItem menu={menu} active={active}>
      <Link to={path}>
        <span>{label}</span>
      </Link>
      {/* {menu && <NestedMenu menu={menu} />} */}
    </NavListItem>
  )
}

export default NavBar
