import styled from "styled-components"
import { device, size } from "utils/breakpoints"
import { Grid, Col, Row } from "react-styled-flexboxgrid"

export const FlexCol = styled(Col)`
  display: flex !important;
  flex-direction: column !important;
  flex-basis: ${(props) => props.xs && `calc(100% / (12 / ${props.xs}))`};
  width: ${(props) => props.xs && `calc(100% / (12 / ${props.xs}))`};
  max-width: ${(props) => props.xs && `calc(100% / (12 / ${props.xs}))`};

  ${(props) =>
    props.c1 && props.c1[0] < size.tablet
      ? `
      @media (min-width: ${props.c1[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c1[1]}));
        width: calc(100% / (12 / ${props.c1[1]}));  
        max-width: calc(100% / (12 / ${props.c1[1]}));  
    } 
      `
      : null};

  ${(props) =>
    props.c2 && props.c2[0] < size.tablet
      ? `
      @media (min-width: ${props.c1[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c2[1]}));
        width: calc(100% / (12 / ${props.c2[1]}));  
        max-width: calc(100% / (12 / ${props.c2[1]}));  
      } 
      `
      : null};

  @media (min-width: ${(props) => props.sm && `768px`}) {
    flex-basis: ${(props) => props.sm && `calc(100% / (12 / ${props.sm}))`};
    width: ${(props) => props.sm && `calc(100% / (12 / ${props.sm}))`};
    max-width: ${(props) => props.sm && `calc(100% / (12 / ${props.sm}))`};
  }

  ${(props) =>
    props.c1 && props.c1[0] < size.desktop && props.c1[0] > size.tablet
      ? `
      @media (min-width: ${props.c1[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c1[1]}));
        width: calc(100% / (12 / ${props.c1[1]}));  
        max-width: calc(100% / (12 / ${props.c1[1]}));  
      } 
      `
      : null};

  ${(props) =>
    props.c2 && props.c2[0] < size.desktop && props.c2[0] > size.tablet
      ? `
      @media (min-width: ${props.c2[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c2[1]}));
        width: calc(100% / (12 / ${props.c2[1]}));  
        max-width: calc(100% / (12 / ${props.c2[1]}));  
      } 
      `
      : null};

  @media (min-width: ${(props) => props.md && `1024px`}) {
    flex-basis: ${(props) => props.md && `calc(100% / (12 / ${props.md}))`};
    width: ${(props) => props.md && `calc(100% / (12 / ${props.md}))`};
    max-width: ${(props) => props.md && `calc(100% / (12 / ${props.md}))`};
  }

  ${(props) =>
    props.c1 && props.c1[0] < size.large && props.c1[0] > size.desktop
      ? `
      @media (min-width: ${props.c1[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c1[1]}));
        width: calc(100% / (12 / ${props.c1[1]}));  
        max-width: calc(100% / (12 / ${props.c1[1]}));  
    } 
      `
      : null};

  ${(props) =>
    props.c2 && props.c2[0] < size.large && props.c2[0] > size.desktop
      ? `
      @media (min-width: ${props.c2[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c2[1]}));
        width: calc(100% / (12 / ${props.c2[1]}));  
        max-width: calc(100% / (12 / ${props.c2[1]}));  
    } 
      `
      : null};

  @media (min-width: ${(props) => props.lg && `1280px`}) {
    flex-basis: ${(props) => props.lg && `calc(100% / (12 / ${props.lg}))`};
    width: ${(props) => props.lg && `calc(100% / (12 / ${props.lg}))`};
    max-width: ${(props) => props.lg && `calc(100% / (12 / ${props.lg}))`};
  }

  ${(props) =>
    props.c1 && props.c1[0] > size.large
      ? `
      @media (min-width: ${props.c1[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c1[1]}));
        width: calc(100% / (12 / ${props.c1[1]}));  
        max-width: calc(100% / (12 / ${props.c1[1]}));  
    } 
      `
      : null};

  ${(props) =>
    props.c2 && props.c2[0] > size.large
      ? `
      @media (min-width: ${props.c2[0]}px) {
        flex-basis: calc(100% / (12 / ${props.c2[1]}));
        width: calc(100% / (12 / ${props.c2[1]}));  
        max-width: calc(100% / (12 / ${props.c2[1]}));  
    } 
      `
      : null};

  /* aligns the column child HORIZONTALLY */
  > * {
    align-self: ${(props) =>
      props.xsAlignX && props.xsAlignX === "center"
        ? "center"
        : props.xsAlignX
        ? `flex-${props.xsAlignX}`
        : null};

    @media ${device.tablet} {
      align-self: ${(props) =>
        props.smAlignX && props.smAlignX === "center"
          ? "center"
          : props.smAlignX
          ? `flex-${props.smAlignX}`
          : null};
    }

    @media ${device.desktop} {
      align-self: ${(props) =>
        props.mdAlignX && props.mdAlignX === "center"
          ? "center"
          : props.mdAlignX
          ? `flex-${props.mdAlignX}`
          : null};
    }

    @media ${device.large} {
      align-self: ${(props) =>
        props.lgAlignX && props.lgAlignX === "center"
          ? "center"
          : props.lgAlignX
          ? `flex-${props.lgAlignX}`
          : null};
    }
  }

  /* aligns the column child VERTICALLY */
  justify-content: ${(props) =>
    props.xsAlignY && props.xsAlignY === "center"
      ? "center"
      : props.xsAlignY
      ? `flex-${props.xsAlignY}`
      : null};

  @media ${device.tablet} {
    justify-content: ${(props) =>
      props.smAlignY && props.smAlignY === "center"
        ? "center"
        : props.smAlignY
        ? `flex-${props.smAlignY}`
        : null};
  }

  @media ${device.desktop} {
    justify-content: ${(props) =>
      props.mdAlignY && props.mdAlignY === "center"
        ? "center"
        : props.mdAlignY
        ? `flex-${props.mdAlignY}`
        : null};
  }

  @media ${device.large} {
    justify-content: ${(props) =>
      props.lgAlignY && props.lgAlignY === "center"
        ? "center"
        : props.lgAlignY
        ? `flex-${props.lgAlignY}`
        : null};
  }
`
export const FlexRow = styled(Row)`
  /* sets the gap and rowGap @ each breakpoint */
  ${FlexCol} {
    margin-bottom: ${(props) =>
      props.xsRowGap
        ? `${props.xsRowGap}px`
        : props.xsGap
        ? `${props.xsGap}px`
        : `${props.theme.xsGridGap}px`};

    padding: ${(props) =>
      props.xsGap
        ? ` 0 ${props.xsGap / 2}px`
        : `0 ${props.theme.xsGridGap / 2}px`};

    @media ${device.tablet} {
      margin-bottom: ${(props) =>
        props.smRowGap
          ? `${props.smRowGap}px`
          : props.smGap
          ? `${props.smGap}px`
          : props.xsRowGap
          ? `${props.xsRowGap}px`
          : props.xsGap
          ? `${props.xsGap}px`
          : `${props.theme.smGridGap}px`};

      padding: ${(props) =>
        props.smGap
          ? ` 0 ${props.smGap / 2}px`
          : props.xsGap
          ? ` 0 ${props.xsGap / 2}px`
          : `0 ${props.theme.smGridGap / 2}px`};
    }

    @media ${device.desktop} {
      margin-bottom: ${(props) =>
        props.mdRowGap
          ? `${props.mdRowGap}px`
          : props.mdGap
          ? `${props.mdGap}px`
          : props.smRowGap
          ? `${props.smRowGap}px`
          : props.smGap
          ? `${props.smGap}px`
          : props.xsRowGap
          ? `${props.xsRowGap}px`
          : props.xsGap
          ? `${props.xsGap}px`
          : `${props.theme.mdGridGap}px`};

      padding: ${(props) =>
        props.mdGap
          ? ` 0 ${props.mdGap / 2}px`
          : props.smGap
          ? ` 0 ${props.smGap / 2}px`
          : props.xsGap
          ? ` 0 ${props.xsGap / 2}px`
          : `0 ${props.theme.mdGridGap / 2}px`};
    }

    @media ${device.large} {
      margin-bottom: ${(props) =>
        props.lgRowGap
          ? `${props.lgRowGap}px`
          : props.lgGap
          ? `${props.lgGap}px`
          : props.mdRowGap
          ? `${props.mdRowGap}px`
          : props.mdGap
          ? `${props.mdGap}px`
          : props.smRowGap
          ? `${props.smRowGap}px`
          : props.smGap
          ? `${props.smGap}px`
          : props.xsRowGap
          ? `${props.xsRowGap}px`
          : props.xsGap
          ? `${props.xsGap}px`
          : `${props.theme.lgGridGap}px`};

      padding: ${(props) =>
        props.lgGap
          ? ` 0 ${props.lgGap / 2}px`
          : props.mdGap
          ? ` 0 ${props.mdGap / 2}px`
          : props.smGap
          ? ` 0 ${props.smGap / 2}px`
          : props.xsGap
          ? ` 0 ${props.xsGap / 2}px`
          : `0 ${props.theme.lgGridGap / 2}px`};
    }
  }

  /* accounts for padding on side of each row */

  margin: ${(props) =>
    props.xsGap
      ? `0 -${props.xsGap / 2}px`
      : `0 -${props.theme.xsGridGap / 2}px`};

  @media ${device.tablet} {
    margin: ${(props) =>
      props.xsGap
        ? `0 -${props.xsGap / 2}px`
        : props.smGap
        ? `0 -${props.smGap / 2}px`
        : `0 -${props.theme.smGridGap / 2}px`};
  }

  @media ${device.desktop} {
    margin: ${(props) =>
      props.xsGap
        ? `0 -${props.xsGap / 2}px`
        : props.smGap
        ? `0 -${props.smGap / 2}px`
        : props.mdGap
        ? `0 -${props.mdGap / 2}px`
        : `0 -${props.theme.mdGridGap / 2}px`};
  }

  @media ${device.large} {
    margin: ${(props) =>
      props.xsGap
        ? `0 -${props.xsGap / 2}px`
        : props.smGap
        ? `0 -${props.smGap / 2}px`
        : props.mdGap
        ? `0 -${props.mdGap / 2}px`
        : props.lgGap
        ? `0 -${props.lgGap / 2}px`
        : `0 -${props.theme.lgGridGap / 2}px`};
  }

  /* removes the gap at the bottom of the row  */
  &:last-of-type {
    margin-bottom: ${(props) =>
      props.xsRowGap
        ? `-${props.xsRowGap}px`
        : props.xsGap
        ? `-${props.xsGap}px`
        : `-${props.theme.xsGridGap}px`};
    @media ${device.tablet} {
      margin-bottom: ${(props) =>
        props.xsRowGap
          ? `-${props.xsRowGap}px`
          : props.xsGap
          ? `-${props.xsGap}px`
          : props.smRowGap
          ? `-${props.smRowGap}px`
          : props.smGap
          ? `-${props.smGap}px`
          : `-${props.theme.smGridGap}px`};
    }

    @media ${device.desktop} {
      margin-bottom: ${(props) =>
        props.xsRowGap
          ? `-${props.xsRowGap}px`
          : props.xsGap
          ? `-${props.xsGap}px`
          : props.smRowGap
          ? `-${props.smRowGap}px`
          : props.smGap
          ? `-${props.smGap}px`
          : props.mdRowGap
          ? `-${props.mdRowGap}px`
          : props.mdGap
          ? `-${props.mdGap}px`
          : `-${props.theme.mdGridGap}px`};
    }

    @media ${device.large} {
      margin-bottom: ${(props) =>
        props.xsRowGap
          ? `-${props.xsRowGap}px`
          : props.xsGap
          ? `-${props.xsGap}px`
          : props.smRowGap
          ? `-${props.smRowGap}px`
          : props.smGap
          ? `-${props.smGap}px`
          : props.mdRowGap
          ? `-${props.mdRowGap}px`
          : props.mdGap
          ? `-${props.mdGap}px`
          : props.lgRowGap
          ? `-${props.lgRowGap}px`
          : props.lgGap
          ? `-${props.lgGap}px`
          : `-${props.theme.lgGridGap}px`};
    }
  }
`

export const FlexGrid = styled(Grid)`
  padding: 0;
  width: 100%;
  /* overflow: hidden; */
`
