import React, { useState } from "react"
import { NavBar } from "../NavBar"
import { Icon } from "helpers/getIcon"
import { useMedia } from "helpers/useMedia.js"
import { WhiteButton } from "../../globalComponents"

import {
  HeaderContainer,
  HeroImage,
  HeaderContentWrapper,
  HeaderText,
  HeaderSeacrhContainer,
  HeaderSearchInputTitle,
  HeaderSeacrhInput,
  SearchSubmitButton,
  HeaderExcerpt,
} from "./style"
import { navigate } from "gatsby"

const Header = ({
  internal,
  title,
  heroImage,
  query,
  setQuery,
  home,
  setCurrentPage,
}) => {
  const [internalQuery, setInternalQuery] = useState("")
  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )
  const excerpt =
    "Search our Public Library and get free access to materials relating to philanthropy with a predominant focus on workplace giving."

  const handleType = (value) => {
    console.log(value)
    if (internal) {
      setInternalQuery(value)
    } else if (home && setQuery) {
      setQuery(value)
      setCurrentPage(1)
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/?q=${internalQuery}`)
    }
  }
  return (
    <HeaderContainer id="header">
      <NavBar />
      <HeaderContentWrapper internal={internal}>
        {heroImage && <HeroImage fluid={heroImage} internal={internal} />}
        <HeaderText internal={internal}>
          {title && <h1>{title}</h1>}
          {excerpt && (
            <HeaderExcerpt internal={internal}>{excerpt}</HeaderExcerpt>
          )}
        </HeaderText>
        <HeaderSearchInputTitle internal={internal}>
          Enter a title, subject or author name to get started…
        </HeaderSearchInputTitle>
        <HeaderSeacrhContainer internal={internal} id="header-input">
          <Icon name={"magnify"} />
          <HeaderSeacrhInput
            id="searchInput"
            onKeyDown={handleKeyDown}
            internal={internal}
            style={{ marginBottom: "25px" }}
            value={query}
            placeholder={
              internal
                ? "Search our resources…"
                : isDesktop
                ? "Enter a title, subject or author name to get started…"
                : "Search our resources…"
            }
            onChange={(e) => {
              handleType(e.target.value)
              // setCurrentPage(1)
            }}
          />
          <WhiteButton label="Search" link={`/?q=${internalQuery}`} />
          <Icon name={"search-circle"} />
        </HeaderSeacrhContainer>
      </HeaderContentWrapper>
    </HeaderContainer>
  )
}

export default Header
