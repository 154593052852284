const primaryColor = "#4EA3B3"
const secondaryColor = "#3C8D9D"
const white = "#FFFFFF"

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: secondaryColor,
  btnTextColor: "#007E8F",
  teal: primaryColor,
  darkTeal: secondaryColor,
  green: "#108673",
  navy: "#2C394B",
  lightGreen: "#42BBA3",
  midGrey: "#656f76",
  linkWater: "#C4CFD7",
  solitude: "#E2E8ED",
  balticSea: "#313b44",
  aliceBlue: "#F9FAFB",
  mobileGutter: 30,
  desktopGutter: 40,
  mainMaxWidth: 1200,
  xsGridGap: 20,
  smGridGap: 20,
  mdGridGap: 30,
  lgGridGap: 30,
}

export default theme
