import React, { useContext } from "react"
import { PreviewContext } from "context/PreviewContext"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "UI/Header"
import { Footer } from "UI/Footer"
import "../fonts/Gotham/stylesheet.css"

const Layout = ({
  internal,
  home,
  children,
  pageData,
  query,
  setQuery,
  setCurrentPage,
  customHeaderTitle,
}) => {
  const { title, headerSettings, featuredImage } = pageData
  const { previewData } = useContext(PreviewContext)
  const previewTitle =
    previewData?.templateData?.headerSettings?.customPageTitle ||
    previewData?.templateData?.title
  const pageContextTitle = headerSettings?.customPageTitle || title
  const pageTitle = previewTitle || pageContextTitle
  const heroImage = previewData?.templateData?.featuredImage || featuredImage?.node

  return (
    <ThemeProvider theme={theme}>
      <div className="app-wrapper">
        <Header
          internal={internal}
          home={home}
          heroImage={heroImage}
          // title={pageTitle}
          title={customHeaderTitle || "Public Library"}
          query={query}
          setQuery={setQuery}
          setCurrentPage={setCurrentPage}
        />
        <main>{children}</main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
