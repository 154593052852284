import React from "react"
import PropTypes from "prop-types"
//Documentation
//https://www.npmjs.com/package/react-styled-flexboxgrid
//https://loicmahieu.github.io/react-styled-flexboxgrid/demo/index.html

// gap
// rowGap => {[ up to 4 b.p ]}

import { FlexRow } from "./style"
const Row = (props) => {
  return <FlexRow {...props} className={`${props.className} row`}/>
}

export default Row

Row.propTypes = {
  gap: PropTypes.array,
  rowGap: PropTypes.array,
}
