import React from "react"
import { ReactComponent as AvenueLogo } from "images/avenue-logo.svg"
import { Grid, Row, Col } from "UI/Grid"
import { SocialLinks } from "UI/SocialLinks"
import { useStaticQuery, graphql } from "gatsby"
import { Icon } from "helpers/getIcon"

import {
  FooterContainer,
  FooterLink,
  Copyright,
  LogoWrapper,
  FooterLinksMenu,
  IconText,
  FooterPhone,
  FooterAddress,
  FooterMenuLogo,
  FooterCol,
  FooterPhoneNumber,
} from "./style"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wp {
          siteOptions {
            siteSettings {
              footerText
              footerLinks {
                ... on WpPage {
                  title
                  link
                }
              }
              youtubeLink
              facebookLink
              linkedInLink
              address
              phoneNumber
              faxNumber
            }
          }
        }
      }
    `
  )

  const siteSettings = data?.wp?.siteOptions?.siteSettings
  const footerText = siteSettings?.footerText
  const footerLinks = siteSettings?.footerLinks

  return (
    <FooterContainer>
      <Grid mainColumn>
        <Row mdGap={0} mdRowGap={0}>
          <Col xs={12} md={4} xsAlignX="start">
            <IconText>
              <Icon name={"location"} />
              <a
                href={`https://www.google.com/maps?q=${siteSettings?.address}`}
              >
                <FooterAddress>{siteSettings?.address}</FooterAddress>
              </a>
            </IconText>
          </Col>
          <Col xs={12} md={3} xsAlignX="start">
            <IconText>
              <FooterPhone>
                <span>P</span>
              </FooterPhone>
              <a href={`tel:${siteSettings?.phoneNumber}`}>
                <FooterPhoneNumber>
                  {siteSettings?.phoneNumber}
                </FooterPhoneNumber>
              </a>
            </IconText>

            <IconText>
              <FooterPhone>
                <span>F</span>
              </FooterPhone>
              <a href={`tel:${siteSettings?.faxNumber}`}>
                <FooterPhoneNumber>{siteSettings?.faxNumber}</FooterPhoneNumber>
              </a>
            </IconText>
          </Col>

          {footerLinks && (
            <Col xs={12} md={5} xsAlignX="start">
              <FooterMenuLogo>
                <FooterLinksMenu>
                  {footerLinks.map((item, index) => {

                    console.log(item)
                    return (
                      <FooterLink key={index} to={item.link.replace(/https?:\/\/[^\/]+/i, "")}>
                        {item.title}
                      </FooterLink>
                    )
                  })}
                </FooterLinksMenu>
                <Icon name={"charity"} />
              </FooterMenuLogo>
            </Col>
          )}

          <FooterCol xs={12} xsAlignX="start">
            {footerText && (
              <Copyright dangerouslySetInnerHTML={{ __html: footerText }} />
            )}

            <SocialLinks
              facebook={siteSettings?.facebookLink}
              linkedIn={siteSettings?.linkedInLink}
              youtube={siteSettings?.youtubeLink}
            />
          </FooterCol>
        </Row>
      </Grid>
    </FooterContainer>
  )
}
export default Footer
