import React from "react"
import { ReactComponent as Search } from "icons/search.svg"
import { ReactComponent as MailIcon } from "icons/mail.svg"
import { ReactComponent as MessageIcon } from "icons/message.svg"
import { ReactComponent as NameIcon } from "icons/name.svg"
import { ReactComponent as PhoneIcon } from "icons/phone.svg"
import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg"
import { ReactComponent as SeacrhCircle } from "icons/search_circle.svg"
import { ReactComponent as Magnify } from "icons/magnify.svg"
import { ReactComponent as Location } from "icons/location.svg"
import { ReactComponent as Charity } from "icons/charity.svg"
import { ReactComponent as ArrowRight } from "icons/arrow-right.svg"
import { ReactComponent as ArrowCircle } from "icons/arrow-in-circle.svg"

const iconMap = {
  search: { icon: Search },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  name: { icon: NameIcon },
  phone: { icon: PhoneIcon },
  "arrow-left": { icon: ArrowLeft },
  "search-circle": { icon: SeacrhCircle },
  magnify: { icon: Magnify },
  location: { icon: Location },
  charity: { icon: Charity },
  "arrow-right": { icon: ArrowRight },
  "arrow-circle": { icon: ArrowCircle },
}

export const hasIcon = (name) => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
