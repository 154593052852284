import styled from "styled-components"
import { device } from "breakpoints"

export const NestedNav = styled.nav`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: teal;

  @media ${device.desktop} {
    top: auto;
    bottom: -20px;
    left: 0;
    transform: translateY(100%);
    width: 300px;

    flex-direction: column;
  }

  ul {
    list-style: none;
    width: 100%;
  }
`

export const NavListItem = styled.li`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
  line-height: 48px;
  text-transform: uppercase;
  color: ${(props) => props.theme.midGrey};
  padding: 10px 0;

  :last-child {
    padding-right: 0;
    a {
      padding-right: 0;
    }
  }

  @media ${device.desktop} {
    width: auto;
    position: ${(props) => props.menu && "relative"};
  }

  a {
    display: block;
    text-align: left;
    color: ${(props) => (props.active ? "white" : "inherit")};
    width: 95%;
    line-height: 100%;
    background-image: ${(props) =>
      props.active
        ? "linear-gradient(132deg, #41bca3 6%, #245a7f 100%)"
        : "none"};
    padding: 13px 20px;
    border-radius: 100px;
    margin: 0 auto;
    @media ${device.desktop} {
      margin: 0;
      width: 100%;
    }
  }

  span {
    position: relative;
    /* hover underline style */
    ::before {
      display: ${(props) => (props.active ? "none" : "block")};
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: ${(props) => props.theme.midGrey};
    }
  }

  /* opens last NestedNav inwards, can set for 2nd to last too if needed */
  :last-of-type {
    ${NestedNav} {
      @media ${device.desktop} {
        left: auto;
        right: 0;
      }
    }
  }

  :hover {
    /* shows underline */
    span::before {
      opacity: 1;
      bottom: -3px;
    }

    /* displays nestedNav on hover @ desktop */
    ${(props) =>
      props.menu &&
      `
    @media ${device.desktop} {
      ::before {
        content: "";
        transform: translateY(100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
      }
      ${NestedNav} {
        display: flex;
      }
    }
  `};
  }
`

export const NestedNavListItem = styled.li`
  width: 100%;
  border: 1px solid hotpink;
  transition: 70ms;

  a {
    text-align: left;
  }

  :hover {
    background: darkolivegreen;
  }
`
