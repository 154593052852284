import React, { useState, useContext } from "react"
import { NavItem } from "./NavItem"
import Hamburger from "./Hamburger"
import { useStaticQuery, graphql } from "gatsby"
import { PreviewContext } from "context/PreviewContext"
import { ReactComponent as Search } from "images/icons/search.svg"
import { ReactComponent as Logo } from "images/workPlaceLogo.svg"
import {
  LogoWrapper,
  NavBarContainer,
  NavBarMainContainer,
  NavBarInnerContainer,
  NavWrapper,
  Nav,
  NavList,
  QuickSearchWrapper,
} from "./style"

const NavBar = () => {
  const [isNavShown, setNavShown] = useState(false)
  const { previewData } = useContext(PreviewContext)

  const navData = useStaticQuery(
    graphql`
      query {
        allWpPage(
          filter: { uri: { ne: "/" } }
          sort: { fields: [menuOrder], order: ASC }
        ) {
          nodes {
            title
            uri
            pageSettings {
              hideFromNav
            }
          }
        }
      }
    `
  )
  const navMenu = previewData?.pages?.nodes || navData?.allWpPage.nodes

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  return (
    <NavBarMainContainer>
      <NavBarInnerContainer>
        <NavBarContainer>
          <LogoWrapper to="/">
          <img src="/WPG-Logo-Primary.jpg" />
            {/* <Logo /> */}
          </LogoWrapper>
        </NavBarContainer>
        <NavWrapper>
          <Nav isNavShown={isNavShown}>
            <NavList>
              {navMenu?.map((item, index) => {
                if (!item.pageSettings.hideFromNav) {
                  return (
                    <NavItem
                      key={index}
                      label={item.title}
                      path={item.uri}
                      active={false}
                      menu={
                        item.childPages?.nodes?.length
                          ? item.childPages.nodes
                          : null
                      }
                    />
                  )
                }
              })}
            </NavList>
          </Nav>
          {/* <QuickSearchWrapper to="/search">
          <Search />
        </QuickSearchWrapper> */}
          <Hamburger isNavShown={isNavShown} toggleShowNav={toggleShowNav} />
        </NavWrapper>
      </NavBarInnerContainer>
    </NavBarMainContainer>
  )
}

export default NavBar
